<template lang="pug">
tr.interest-rates-list-item
  td {{ template.id }}
  td {{ template.variableInterest.name }}
  td {{ template.date }}
  td {{ template.rate }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'interest-rates-list-item',

  props: {
    template: {
      type: Object,
      required: true
    }
  },

  computed: mapGetters('classifiers', ['classifierById'])
}
</script>
