<template lang="pug">
.login-history-log-list.animated.fadeIn.card
  .card-header {{ $t('title') }}
  .card-body
    fi-table(:fields      = 'fields'
             :items       = 'loginHistory'
             loader       = 'login:history:fetch'
             :empty-label = '$t("notFound")'
             responsive)
      template(#default = '{ items }')
        login-history-log-list-item(v-for = 'item in items'
                                    :key  = 'item.id'
                                    :item = 'item')

    fi-pagination(v-if    = 'loginHistoryPages > 1'
                  :pages  = 'loginHistoryPages'
                  v-model = 'pagination.page')
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiTable from '@/components/FiTable'
import LoginHistoryLogListItem from '@/views/admin/logs/LoginHistoryLogListItem'
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'login-history-log-list',

  components: {
    FiTable,
    LoginHistoryLogListItem,
    FiPagination
  },

  i18nOptions: {},

  data () {
    return {
      selectedCustomer: null,
      searchParams: {
        sort: 'createdAt,desc'
      },
      pagination: {
        page: 0,
        size: 25
      }
    }
  },

  computed: {
    ...mapState('audit', ['loginHistory', 'loginHistoryPages']),
    fields () {
      return [
        {
          label: this.$t('time')
        },
        {
          label: this.$t('customer')
        },
        {
          label: this.$t('userName')
        },
        {
          label: this.$t('ip')
        },
        {
          label: this.$t('authMethod')
        }
      ]
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    }
  },

  created () {
    this.loadLoginHistory({ filters: { ...this.pagination, ...this.searchParams } })
  },

  methods: {
    ...mapActions('audit', ['loadLoginHistory']),
    search: debounce(
      function () {
        this.loadLoginHistory({ filters: { ...this.pagination, ...this.searchParams } })
      }, 250
    )
  }
}
</script>


<i18n>
en:
  title:          "Login history log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  authMethod:     "Authentication method"
et:
  title:          "Login history log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  authMethod:     "Authentication method"
ru:
  title:          "Login history log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  authMethod:     "Authentication method"
</i18n>
