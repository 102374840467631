<template lang="pug">
  .interest-rates-list.animated.fadeIn.card
    .card-header {{ $t('menu:interestRatesList') }}
    .card-body
      fi-table(
        :fields      = 'fields'
        :items       = 'list'
        loader       = 'templates:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          interest-rates-list-item(
            v-for     = 'template in items'
            :key      = 'template.id'
            :template = 'template'
          )
  </template>

<script>
import { mapState, mapActions } from 'vuex'

import FiTable from '@/components/FiTable'
import InterestRatesListItem from './InterestRatesListItem.vue'

export default {
  name: 'interest-rates-list',

  components: {
    FiTable,
    InterestRatesListItem
  },

  data: () => ({
    searchParams: {
      sort: 'id,asc'
    },
    list: []
  }),

  computed: {
    ...mapState('interests', ['interestRates']),
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: this.$t('name'),
          sort: 'variableInterest.name'
        },
        {
          key: 'Date',
          label: this.$t('date'),
          sort: 'date'
        },
        {
          key: 'Rate',
          label: this.$t('rate')
        }
      ]
    }
  },

  watch: {
    searchParams: {
      handler ({ sort }) {
        this.onSort(sort.split(',')[0], sort.split(',')[1])
      },
      deep: true
    }
  },

  async created () {
    await this.loadVariableInterestsRate()
    this.list = [...this.interestRates]
  },

  methods: {
    ...mapActions('interests', ['loadVariableInterestsRate']),
    onSort (sortName, sortBy) {
      if (sortName.split('.').length > 1) {
        sortName = sortName.split('.')
        return this.list.sort((a, b) => {
          return sortBy === 'asc'
            ? (a[sortName[0]][sortName[1]] < b[sortName[0]][sortName[1]] && 1 ? -1 : 0)
            : (a[sortName[0]][sortName[1]] > b[sortName[0]][sortName[1]] && 1 ? -1 : 0)
        })
      }
      this.list.sort((a, b) => {
        return sortBy === 'asc' ? (a[sortName] < b[sortName] && 1 ? -1 : 0) : (a[sortName] > b[sortName] && 1 ? -1 : 0)
      })
    }
  }
}
</script>

<i18n>
  en:
    name:        "Name"
    date:        "Date"
    rate:        "Rate"
  et:
    name:        "Name"
    date:        "Date"
    rate:        "Rate"
  ru:
    name:        "Name"
    date:        "Date"
    rate:        "Rate"
</i18n>
