<template lang="pug">
.data-access-log-list.animated.fadeIn.card
  .card-header {{ $t('title') }}
  .card-body
    form.form-horizontal
      .row
        .form-group.col-lg-6
          .input-group
            .input-group-prepend: span.input-group-text: i.fa.fa-search
            .col.p-0
              fi-multiselect(v-model          = 'selectedCustomerModel'
                             :options         = 'systemCustomersForSelect'
                             :search-callback = 'findSystemCustomers'
                             :loading         = 'systemCustomersLoader'
                             :placeholder     = '$t("selectCustomer")')
        .form-group.col-lg-6
          range-input-date(:start.sync  = 'searchParams.startDate'
                           :end.sync    = 'searchParams.endDate')
    fi-table(:fields      = 'fields'
             :items       = 'auditHistory'
             loader       = 'audit:history:fetch'
             :empty-label = '$t("notFound")'
             responsive)
      template(#default = '{ items }')
        data-access-log-list-item(v-for = 'item in items'
                                  :key  = 'item.id'
                                  :item = 'item')

    fi-pagination(v-if    = 'auditHistoryPages > 1'
                  :pages  = 'auditHistoryPages'
                  v-model = 'pagination.page')
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import DataAccessLogListItem from '@/views/admin/logs/DataAccessLogListItem'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'data-access-log-list',

  components: {
    FiTable,
    FiMultiselect,
    FiPagination,
    DataAccessLogListItem,
    RangeInputDate
  },

  i18nOptions: {},

  data () {
    return {
      selectedCustomer: null,
      searchParams: {
        customerId: '',
        startDate: '',
        endDate: '',
        sort: 'createdAt,desc'
      },
      pagination: {
        page: 0,
        size: 25
      }
    }
  },

  computed: {
    ...mapGetters('customers', ['systemCustomersForSelect']),
    ...mapState('audit', ['auditHistory', 'auditHistoryPages']),
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.searchParams.customerId = (selected && selected.value) || ''
      }
    },
    systemCustomersLoader () {
      return this.$vueLoading.isLoading('customers:systemCustomers:fetch')
    },
    fields () {
      return [
        {
          label: this.$t('time')
        },
        {
          label: this.$t('userId')
        },
        {
          label: this.$t('userName')
        },
        {
          label: this.$t('ip')
        },
        {
          label: this.$t('customer')
        },
        {
          label: this.$t('apiCall')
        }
      ]
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    }
  },

  created () {
    this.loadManagers()
    this.loadAuditHistory({ filters: { ...this.searchParams, ...this.pagination } })
  },

  methods: {
    ...mapActions('customers', ['findSystemCustomers']),
    ...mapActions('audit', ['loadAuditHistory']),
    ...mapActions('classifiers', ['loadManagers']),
    search: debounce(
      function () {
        this.loadAuditHistory({ filters: { ...this.searchParams, ...this.pagination } })
      }, 250
    )
  }
}
</script>


<i18n>
en:
  title:          "Data access log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  apiCall:        "API call"

et:
  title:          "Data access log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  apiCall:        "API call"

ru:
  title:          "Data access log"
  selectCustomer: "Select customer"
  time:           "Time"
  userId:         "User ID"
  notFound:       "Audit history empty"
  userName:       "User name"
  ip:             "IP"
  customer:       "Customer"
  apiCall:        "API call"
</i18n>
