<template lang="pug">
  tr.variable-interests-list-item
    td {{ template.id }}
    td {{ template.name }}
    td {{ template.period.human }}
    td {{ classifierById('currencies', template.currencyId).human }}
    td {{ template.isEnabled ? 'Yes' : 'No' }}
    td {{ template.valueDate }}
  </template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'variable-interests-list-item',

  props: {
    template: {
      type: Object,
      required: true
    }
  },

  computed: mapGetters('classifiers', ['classifierById'])
}
</script>
