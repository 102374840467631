<template lang="pug">
.variable-interests-list.animated.fadeIn.card
  .card-header {{ $t('menu:variableInterestsList') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'list'
      loader       = 'templates:fetch'
      :empty-label = '$t("notFound")'
      :sort.sync   = 'searchParams.sort'
      responsive
    )
      template(#default = '{ items }')
        variable-interests-list-item(
          v-for     = 'template in items'
          :key      = 'template.id'
          :template = 'template'
        )
</template>

<script>
import { mapState, mapActions } from 'vuex'

import FiTable from '@/components/FiTable'
import VariableInterestsListItem from './VariableInterestsListItem.vue'

export default {
  name: 'variable-interests-list',

  components: {
    FiTable,
    VariableInterestsListItem
  },

  data: () => ({
    searchParams: {
      sort: 'id,asc'
    },
    list: []
  }),

  computed: {
    ...mapState('interests', ['variableInterests']),
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: this.$t('name'),
          sort: 'name'
        },
        {
          key: 'Period',
          label: this.$t('period')
        },
        {
          key: 'Currency',
          label: this.$t('currency')
        },
        {
          key: 'Enabled',
          label: this.$t('enabled')
        },
        {
          key: 'ValueDate',
          label: this.$t('valueDate'),
          sort: 'valueDate'
        }
      ]
    }
  },

  watch: {
    searchParams: {
      handler ({ sort }) {
        this.onSort(sort.split(',')[0], sort.split(',')[1])
      },
      deep: true
    }
  },

  async created () {
    await this.loadVariableInterests()
    this.list = [...this.variableInterests]
  },

  methods: {
    ...mapActions('interests', ['loadVariableInterests']),
    onSort (sortName, sortBy) {
      if (sortName.split('.').length > 1) {
        sortName = sortName.split('.')
        return this.list.sort((a, b) => {
          return sortBy === 'asc'
            ? (a[sortName[0]][sortName[1]] < b[sortName[0]][sortName[1]] && 1 ? -1 : 0)
            : (a[sortName[0]][sortName[1]] > b[sortName[0]][sortName[1]] && 1 ? -1 : 0)
        })
      }
      this.list.sort((a, b) => {
        return sortBy === 'asc' ? (a[sortName] < b[sortName] && 1 ? -1 : 0) : (a[sortName] > b[sortName] && 1 ? -1 : 0)
      })
    }
  }
}
</script>

<i18n>
  en:
    name:        "Name"
    period:      "Period"
    currency:    "Currency"
    enabled:     "Enabled"
    valueDate:   "Value date"
  et:
    name:        "Name"
    period:      "Period"
    currency:    "Currency"
    enabled:     "Enabled"
    valueDate:   "Value date"
  ru:
    name:        "Name"
    period:      "Period"
    currency:    "Currency"
    enabled:     "Enabled"
    valueDate:   "Value date"
  </i18n>
