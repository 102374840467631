<template lang="pug">
.variable-interests-view
  .row
    .col
      ul.nav.nav-pills.mb-2
        li.nav-item: router-link.nav-link(:to = '{ name: "VariableInterestsList" }') {{ $t('menu:variableInterestsList') }}
        li.nav-item: router-link.nav-link(:to = '{ name: "InterestRatesList" }') {{ $t('menu:interestRatesList') }}
  router-view
</template>

<script>
export default {
  name: 'variable-interests-view'
}
</script>
