<template lang="pug">
tr.data-access-log-list-item
  td {{ $t('common:formatDatetime', { value: item.createdAt }) }}
  td: router-link(:to = '{ name: "Customer", params: { id: item.userId }}') {{ item.userId }}
  td: router-link(:to = '{ name: "Customer", params: { id: item.userId }}') {{ managerById(item.userId).name }}
  td {{ item.ipAddress }}
  td: router-link(:to = '{ name: "Customer", params: { id: item.customer.id }}') {{ customerName(item.customer) }}
  td {{ item.apiUrl }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'data-access-log-list-item',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['managerById'])
  }
}
</script>
