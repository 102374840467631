<template lang="pug">
tr.login-history-log-list-item
  td {{ $t('common:formatDatetime', { value: item.createdAt }) }}
  td: router-link(:to = '{ name: "Customer", params: { id: item.customerId }}') {{ customerName(item) }}
  td {{ item.userName }}
  td {{ item.ip }}
  td {{ item.authenticationMethod }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'login-history-log-list-item',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('customers', ['customerName'])
  }
}
</script>
