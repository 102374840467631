<template lang="pug">
.logs-view
  .row
    .col
      ul.nav.nav-pills.mb-2
        li.nav-item: router-link.nav-link(:to = '{ name: "DataAccessLog" }') {{ $t('menu:dataAccessLog') }}
        li.nav-item: router-link.nav-link(:to = '{ name: "LoginHistoryLog" }') {{ $t('menu:loginHistoryLog') }}
  router-view
</template>

<script>
export default {
  name: 'logs-view'
}
</script>
